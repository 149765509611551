import { useMutation } from '@graphcommerce/graphql'
import { useCurrentCartId } from '@graphcommerce/magento-cart'
import { useEffect } from 'react'
import { ResetCheckoutDocument } from '../graphql/ResetCheckout.gql'

const useResetCheckout = () => {
  const [resetCheckout] = useMutation(ResetCheckoutDocument)

  const { currentCartId } = useCurrentCartId()

  useEffect(() => {
    if (currentCartId) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      resetCheckout({
        variables: {
          cartId: currentCartId,
        },
      })
    }
  }, [currentCartId, resetCheckout])
}

export { useResetCheckout }
